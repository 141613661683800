<template>
  <nav class="top-nav">
    <ul>
      <li v-for="(item, index) in items" :key="index">
        <router-link :to="item.href" v-if="item.disabled">
          <span>{{ item.text }}</span>
        </router-link>
        <span class="top-nav__active" v-else>{{ item.text }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_breadcrumb.scss';
</style>
